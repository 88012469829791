'use client'
import React, { useState } from 'react'
import Link from 'components/Link'
import Image from 'next/image'

import styles from '../../Footer.module.scss'

import footer_facebook_icon from '../../images/footer_facebook_icon.png'
import footer_linkedIn_icon from '../../images/footer_linkedIn_icon.png'
import footer_instagram_icon from '../../images/footer_instagram_icon.png'
import footer_tiktok_icon from '../../images/footer_tiktok_icon.png'
import footer_twitterX_icon from '../../images/footer_twitterX_icon.png'
import footer_youtube_icon from '../../images/footer_youtube_icon.png'
import telegram_icon from '../../images/footer_telegram_icon.png'
import xiaohongshu_icon from '../../images/footer_xiaohongshu_icon.png'

import footer_facebook_icon_active from '../../images/footer_facebook_icon_active.png'
import footer_linkedIn_icon_active from '../../images/footer_linkedIn_icon_active.png'
import footer_instagram_icon_active from '../../images/footer_instagram_icon_active.png'
import footer_tiktok_icon_active from '../../images/footer_tiktok_icon_active.png'
import footer_twitterX_icon_active from '../../images/footer_twitterX_icon_active.png'
import footer_youtube_icon_active from '../../images/footer_youtube_icon_active.png'
import telegram_icon_active from '../../images/footer_telegram_icon_active.png'
import xiaohongshu_icon_active from '../../images/footer_xiaohongshu_icon_active.png'

const socialInfo = {
  jp: [
    {
      title: 'Bossjob Facebook',
      link: 'https://www.facebook.com/bossjobjp',
      img: footer_facebook_icon.src,
      activeImg: footer_facebook_icon_active.src
    },
    {
      title: 'Bossjob LinkedIn',
      link: 'https://linkedin.com/company/bossjobjp',
      img: footer_linkedIn_icon.src,
      activeImg: footer_linkedIn_icon_active.src
    },
    {
      title: 'Bossjob Instagram',
      link: 'https://www.instagram.com/bossjob_japan',
      img: footer_instagram_icon.src,
      activeImg: footer_instagram_icon_active.src
    },
    {
      title: 'Bossjob Youtube',
      link: 'https://www.youtube.com/@bossjobjp',
      img: footer_youtube_icon.src,
      activeImg: footer_youtube_icon_active.src
    },
    {
      title: 'Bossjob X',
      link: 'https://x.com/bossjobjp',
      img: footer_twitterX_icon.src,
      activeImg: footer_twitterX_icon_active.src
    },
    {
      title: 'Bossjob Tiktok',
      link: 'https://tiktok.com/@bossjobjp',
      img: footer_tiktok_icon.src,
      activeImg: footer_tiktok_icon_active.src
    },
    {
      title: 'Bossjob telegram',
      link: 'https://t.me/jobs',
      img: telegram_icon.src,
      activeImg: telegram_icon_active.src
    },
    {
      title: 'Bossjob xiaohongshu',
      link: 'https://www.xiaohongshu.com/user/profile/655ec9030000000008001fea?xsec_token=&xsec_source=pc_note',
      img: xiaohongshu_icon.src,
      activeImg: xiaohongshu_icon_active.src
    }
  ],
  sg: [
    {
      title: 'Bossjob Facebook',
      link: 'https://www.facebook.com/BossjobSG',
      img: footer_facebook_icon.src,
      activeImg: footer_facebook_icon_active.src
    },
    {
      title: 'Bossjob LinkedIn',
      link: 'https://linkedin.com/company/bossjobofficial',
      img: footer_linkedIn_icon.src,
      activeImg: footer_linkedIn_icon_active.src
    },
    {
      title: 'Bossjob Instagram',
      link: 'https://www.instagram.com/bossjobsg',
      img: footer_instagram_icon.src,
      activeImg: footer_instagram_icon_active.src
    },
    {
      title: 'Bossjob Youtube',
      link: 'https://www.youtube.com/@BossjobOfficial',
      img: footer_youtube_icon.src,
      activeImg: footer_youtube_icon_active.src
    },
    {
      title: 'Bossjob X',
      link: 'https://x.com/BossjobOfficial',
      img: footer_twitterX_icon.src,
      activeImg: footer_twitterX_icon_active.src
    },
    {
      title: 'Bossjob Tiktok',
      link: 'https://www.tiktok.com/@bossjobsg',
      img: footer_tiktok_icon.src,
      activeImg: footer_tiktok_icon_active.src
    },
    {
      title: 'Bossjob telegram',
      link: 'https://t.me/jobs',
      img: telegram_icon.src,
      activeImg: telegram_icon_active.src
    }
  ],
  ph: [
    {
      title: 'Bossjob Facebook',
      link: 'https://www.facebook.com/BossjobPH/',
      img: footer_facebook_icon.src,
      activeImg: footer_facebook_icon_active.src
    },
    {
      title: 'Bossjob LinkedIn',
      link: 'https://www.linkedin.com/company/bossjobofficial/',
      img: footer_linkedIn_icon.src,
      activeImg: footer_linkedIn_icon_active.src
    },
    {
      title: 'Bossjob Instagram',
      link: 'https://www.instagram.com/bossjobph/',
      img: footer_instagram_icon.src,
      activeImg: footer_instagram_icon_active.src
    },
    {
      title: 'Bossjob Youtube',
      link: 'https://www.youtube.com/@BossjobOfficial',
      img: footer_youtube_icon.src,
      activeImg: footer_youtube_icon_active.src
    },
    {
      title: 'Bossjob X',
      link: 'https://x.com/BossjobOfficial',
      img: footer_twitterX_icon.src,
      activeImg: footer_twitterX_icon_active.src
    },
    {
      title: 'Bossjob Tiktok',
      link: 'https://www.tiktok.com/@bossjobph',
      img: footer_tiktok_icon.src,
      activeImg: footer_tiktok_icon_active.src
    },
    {
      title: 'Bossjob telegram',
      link: 'https://t.me/jobs',
      img: telegram_icon.src,
      activeImg: telegram_icon_active.src
    }
  ]
}

const SocialList = (props) => {
  const { isMobile } = props
  const countryKey = ['jp', 'sg', 'ph'].includes(props.countryKey) ? props.countryKey : 'ph'
  const socialInfoArr = socialInfo[countryKey]

  const [iconType, setIconType] = useState('')

  const handleHoverIcon = (iconType) => {
    setIconType(iconType)
  }

  return (
    <>
      {socialInfoArr.map((item) => (
        <Link
          className={styles.socialLink}
          to={item.link}
          key={item.link}
          external
          title={item.title}
          rel='noopener noreferrer'
          onMouseEnter={() => handleHoverIcon(item.title)}
          onMouseLeave={() => handleHoverIcon('')}
        >
          <Image src={item.img} alt={item.title} width={30} height={30} />
          {!isMobile && iconType === item.title ? (
            <Image
              className={styles.hoverIcon}
              src={item.activeImg}
              alt={item.title}
              width={30}
              height={30}
            />
          ) : null}
        </Link>
      ))}
    </>
  )
}

export default SocialList

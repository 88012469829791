import React, { useEffect, useState } from 'react'
import Link from 'components/Link'
import Text from 'components/Text'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import styles from '../Header.module.scss'
import linkToHunt from 'helpers/linkToHunt'
import { isMobile } from 'react-device-detect'
import { MessageCenter } from './MsgCenter'
import { ReactComponent as JobSvg } from '../imgs/svg/job.svg'
import { ReactComponent as JobActiveSvg } from '../imgs/svg/job_active.svg'
import { ReactComponent as SettingSvg } from '../imgs/svg/setting.svg'
import { ReactComponent as SettingActiveSvg } from '../imgs/svg/setting_active.svg'
import { ReactComponent as PersonSvg } from '../imgs/svg/person.svg'
import { ReactComponent as PersonActiveSvg } from '../imgs/svg/person_active.svg'
import { ReactComponent as NotificationSvg } from '../imgs/svg/notification.svg'
import { ReactComponent as NotificationActiveSvg } from '../imgs/svg/notification_active.svg'
import { ReactComponent as LogoutSvg } from '../imgs/svg/logout.svg'
import { ReactComponent as LogoutActiveSvg } from '../imgs/svg/logout_active.svg'
import { removeCookie } from 'helpers/cookies'

interface IProps {
  langKey: string
  lang: any
  pathname: string
  setDorpDownOpen: any
  onUnreadChange: any
  // handleShowMenu: Function
}

const DropDownMenu = (props: IProps, ref: React.LegacyRef<HTMLDivElement>) => {
  const { langKey, lang, setDorpDownOpen, onUnreadChange } = props

  const { hiring, accountSettings, logOut, recommendedJobs, manageResume } = lang || {}
  const [msgOpen, setMsgOpen] = useState(false)
  const [unread, setUnread] = useState(0)
  const [currentHoverMenu, setCurrentHoverMenu] = useState('')

  useEffect(() => {
    onUnreadChange?.(unread)
  }, [unread])

  const handleLogOut = () => {
    removeCookie('remind_user_complete_resume')
    postNotification('LOGOUT')
  }
  const style = { color: '#121212' }

  if (!isMobile) {
    return (
      <div className={styles.headerMenu} style={{ padding: '8px' }} ref={ref}>
        <ul className={styles.headerMenuList}>
          <li>
            <Link
              to={'/' + langKey + '/my-jobs?page=1'}
              className={styles.headerMenuLink}
              onMouseEnter={() => setCurrentHoverMenu('my-jobs')}
              onMouseLeave={() => setCurrentHoverMenu('')}
            >
              {currentHoverMenu === 'my-jobs' ? <JobActiveSvg /> : <JobSvg />}
              <span className={styles.linkText}>{recommendedJobs}</span>
            </Link>
          </li>
          <li>
            <Link
              to='/settings'
              className={styles.headerMenuLink}
              onMouseEnter={() => setCurrentHoverMenu('settings')}
              onMouseLeave={() => setCurrentHoverMenu('')}
            >
              {currentHoverMenu === 'settings' ? <SettingActiveSvg /> : <SettingSvg />}
              <span className={styles.linkText}>{accountSettings}</span>
            </Link>
          </li>

          <li>
            <Link
              to={linkToHunt('boss')}
              aTag
              external
              className={styles.headerMenuLink}
              onMouseEnter={() => setCurrentHoverMenu('hunt-boss')}
              onMouseLeave={() => setCurrentHoverMenu('')}
            >
              {currentHoverMenu === 'hunt-boss' ? <PersonActiveSvg /> : <PersonSvg />}
              <span className={styles.linkText}>{hiring}</span>
            </Link>
          </li>
          <>
            <li
              className={`${styles.headerMenuItemSpe}`}
              onMouseEnter={() => setCurrentHoverMenu('notification')}
              onMouseLeave={() => setCurrentHoverMenu('')}
            >
              <div
                className={styles.headerMenuLink}
                onClick={() => {
                  // console.log('open')
                  setMsgOpen(true)
                  setDorpDownOpen(false)
                }}
              >
                <span className={styles.notificationIconWrapper} data-hasUnread={Boolean(unread)}>
                  {currentHoverMenu === 'notification' ? (
                    <NotificationActiveSvg />
                  ) : (
                    <NotificationSvg />
                  )}
                </span>
                <span className={styles.linkText}>{lang?.notification || 'Notification'}</span>
              </div>
            </li>
            <MessageCenter open={msgOpen} onClose={setMsgOpen} onUnreadChange={setUnread} />
          </>

          <li
            className={`${styles.headerMenuItemSpe}`}
            onMouseEnter={() => setCurrentHoverMenu('logout')}
            onMouseLeave={() => setCurrentHoverMenu('')}
          >
            <div className={styles.headerMenuLink} onClick={() => handleLogOut()}>
              {currentHoverMenu === 'logout' ? <LogoutActiveSvg /> : <LogoutSvg />}
              <span className={styles.linkText}>{logOut}</span>
            </div>
          </li>
        </ul>
      </div>
    )
  }
  return (
    <div className={styles.headerMenu} style={{ padding: '12px 0' }} ref={ref}>
      <ul className={styles.headerMenuList}>
        <li>
          <Link to={'/' + langKey + '/manage-profile'} className={styles.headerMenuLink}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
            >
              <path
                d='M11.4343 7.73332C10.846 7.73332 10.3692 8.21016 10.3692 8.79829C10.3692 9.38642 10.846 9.86327 11.4343 9.86327C12.0225 9.86327 12.4994 9.38642 12.4994 8.79829C12.4994 8.21016 12.0225 7.73332 11.4343 7.73332ZM9.03582 8.79829C9.03582 7.4737 10.1097 6.39998 11.4343 6.39998C12.7588 6.39998 13.8327 7.4737 13.8327 8.79829C13.8327 9.4551 13.5687 10.0502 13.141 10.4833C14.054 11.115 14.6521 12.1695 14.6521 13.3638V14.0305H13.3187V13.3638C13.3187 12.2261 12.4419 11.2932 11.3271 11.2039C10.2112 11.2922 9.33334 12.2256 9.33334 13.364V14.0306H8V13.364C8 12.1123 8.65699 11.0141 9.64493 10.3954C9.2661 9.97129 9.03582 9.41169 9.03582 8.79829Z'
                fill='#121212'
              />
              <path
                d='M2 3.33333C2 2.22876 2.89543 1.33333 4 1.33333H12C13.1046 1.33333 14 2.22876 14 3.33333V5.54167H12.6667V3.33333C12.6667 2.96514 12.3682 2.66667 12 2.66667H4C3.63181 2.66667 3.33333 2.96514 3.33333 3.33333V12C3.33333 12.3682 3.63181 12.6667 4 12.6667H6.57143V14H4C2.89543 14 2 13.1046 2 12V3.33333Z'
                fill='#121212'
              />
              <path
                d='M4.66667 5.33333C4.66667 4.96514 4.96514 4.66667 5.33333 4.66667L10.3333 4.66667C10.7015 4.66667 11 4.96514 11 5.33333C11 5.70152 10.7015 6 10.3333 6H5.33333C4.96514 6 4.66667 5.70152 4.66667 5.33333Z'
                fill='#121212'
              />
              <path
                d='M4.66667 8C4.66667 7.63181 4.96514 7.33333 5.33333 7.33333H6.66667C7.03486 7.33333 7.33333 7.63181 7.33333 8C7.33333 8.36819 7.03486 8.66667 6.66667 8.66667H5.33333C4.96514 8.66667 4.66667 8.36819 4.66667 8Z'
                fill='#121212'
              />
            </svg>
            <Text textStyle='base' style={style}>
              {manageResume}
            </Text>
          </Link>
        </li>
        <li>
          <Link to={'/' + langKey + '/my-jobs?page=1'} className={styles.headerMenuLink}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
            >
              <path
                d='M11.333 3.16666V4.5H12.6663C13.7709 4.5 14.6663 5.39543 14.6663 6.5V13.1667C14.6663 14.2712 13.7709 15.1667 12.6663 15.1667H3.33301C2.22844 15.1667 1.33301 14.2712 1.33301 13.1667V6.5C1.33301 5.39543 2.22844 4.49999 3.33301 4.49999H4.66634V3.16666C4.66634 2.43028 5.2633 1.83333 5.99968 1.83333H9.99967C10.7361 1.83333 11.333 2.43028 11.333 3.16666ZM5.99967 7.16666H4.66634V5.83333H3.33301C2.96482 5.83333 2.66634 6.13181 2.66634 6.5L2.66634 9.83333H13.333V6.5C13.333 6.13181 13.0345 5.83333 12.6663 5.83333H11.333V7.16666H9.99967V5.83333H5.99967V7.16666ZM13.333 11.1667H2.66634L2.66634 13.1667C2.66634 13.5349 2.96482 13.8333 3.33301 13.8333H12.6663C13.0345 13.8333 13.333 13.5349 13.333 13.1667V11.1667ZM9.99967 4.5V3.16666L5.99968 3.16666V4.5H9.99967Z'
                fill='#121212'
              />
            </svg>
            <Text textStyle='base' style={style}>
              {recommendedJobs}
            </Text>
          </Link>
        </li>

        <li>
          <Link to='/settings' className={styles.headerMenuLink}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
            >
              <path
                d='M8.0444 5.71887C6.50853 5.71887 5.26335 6.96383 5.26335 8.49971C5.26335 10.0356 6.50853 11.2805 8.0444 11.2805C9.58027 11.2805 10.8254 10.0356 10.8254 8.49971C10.8254 6.96383 9.58027 5.71887 8.0444 5.71887ZM6.59668 8.49971C6.59668 7.70034 7.24478 7.05221 8.0444 7.05221C8.84401 7.05221 9.49211 7.70034 9.49211 8.49971C9.49211 9.29908 8.84401 9.94721 8.0444 9.94721C7.24478 9.94721 6.59668 9.29908 6.59668 8.49971Z'
                fill='#121212'
              />
              <path
                d='M5.45099 1.83362C4.72466 1.83362 4.05542 2.2274 3.70268 2.86233L1.11008 7.529C0.774503 8.13304 0.774503 8.86753 1.11008 9.47157L3.70268 14.1382C4.05542 14.7732 4.72466 15.167 5.45099 15.167H10.5486C11.2749 15.167 11.9442 14.7732 12.2969 14.1382L14.8895 9.47157C15.2251 8.86753 15.2251 8.13304 14.8895 7.529L12.2969 2.86233C11.9442 2.2274 11.2749 1.83362 10.5486 1.83362H5.45099ZM4.86822 3.50986C4.9858 3.29821 5.20888 3.16695 5.45099 3.16695L10.5486 3.16695C10.7907 3.16695 11.0138 3.29821 11.1314 3.50986L13.7239 8.17652C13.8358 8.37787 13.8358 8.6227 13.7239 8.82405L11.1314 13.4907C11.0138 13.7024 10.7907 13.8336 10.5486 13.8336H5.45099C5.20888 13.8336 4.9858 13.7024 4.86822 13.4907L2.27563 8.82405C2.16377 8.6227 2.16377 8.37787 2.27563 8.17652L4.86822 3.50986Z'
                fill='#121212'
              />
            </svg>
            <Text textStyle='base' style={style}>
              {accountSettings}
            </Text>
          </Link>
        </li>

        <li className={`${styles.headerMenuItemSpe}`}>
          <div className={styles.headerMenuLink} onClick={() => handleLogOut()}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
            >
              <path
                d='M11.3333 3.83333V2.5H4C2.89543 2.5 2 3.39543 2 4.5V12.5C2 13.6046 2.89543 14.5 4 14.5H11.3333V13.1667H4C3.63181 13.1667 3.33333 12.8682 3.33333 12.5V4.5C3.33333 4.13181 3.63181 3.83333 4 3.83333H11.3333Z'
                fill='#121212'
              />
              <path
                d='M12.3905 9.16667L6.66667 9.16667V7.83333L12.3905 7.83333L10.8619 6.30474C10.6016 6.04439 10.6016 5.62228 10.8619 5.36193C11.1223 5.10158 11.5444 5.10158 11.8047 5.36193L14.4714 8.0286C14.7318 8.28895 14.7318 8.71106 14.4714 8.97141L11.8047 11.6381C11.5444 11.8984 11.1223 11.8984 10.8619 11.6381C10.6016 11.3777 10.6016 10.9556 10.8619 10.6953L12.3905 9.16667Z'
                fill='#121212'
              />
            </svg>
            <Text textStyle='base' style={style}>
              {logOut}
            </Text>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default React.forwardRef(DropDownMenu)

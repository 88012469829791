import React, { useState, useEffect } from 'react'
import './index.scss'
import { Cascader } from 'antd'
import { StyleProvider } from '@ant-design/cssinjs'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { isMobile } from 'react-device-detect'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { getCountryId } from 'helpers/country'
import { uniqBy } from 'lodash-es'

type Variant = 'standard' | 'borderLess'
interface initProps {
  label?: string
  variant?: Variant
  required?: boolean
  error?: any
  onChange?: (any) => void
  preference?: any
  placeholder?: string
  suffixIcon?: any
}

const CascaderComponent = ({
  label = '',
  variant,
  required = false,
  error,
  onChange,
  preference,
  suffixIcon: SuffixIcon,
  ...rest
}: initProps) => {
  const configCountryLists = useSharedData('CONFIG')?.country_lists ?? []
  const AllText = useSharedData('DICTIONARY')?.companyDetail?.overview?.All
  const countryId = getCountryId()
  const showFirstLevel = /^(?:193|229|38|29|154|227|203|190)$/.test(String(countryId))
  const findLists = configCountryLists?.find((e) => e.id === countryId)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [value, setValue] = useState<any[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)
  const [countryLists, setCountryLists] = useState<any[]>([])

  useEffect(() => {
    if (findLists?.id) {
      const regionLists = findLists.region_lists.map((e) => ({
        ...e,
        type: 'region'
      }))
      regionLists?.unshift({
        id: findLists.id,
        key: findLists.key,
        value: showFirstLevel ? AllText + ' ' + findLists.value : findLists.value,
        type: 'country'
      })

      const uniArr = uniqBy(regionLists, 'id')
      const list = []

      uniArr.map((e) => {
        const item = {
          id: e.id,
          key: e.key,
          label: e.value || e.display_name,
          value: e.id,
          type: e.type || 'region',
          children: []
        }

        if (e.location_lists?.length > 0 && !showFirstLevel) {
          item.children.push({
            id: e.id,
            value: e.id,
            key:
              e.location_lists.length === 1 && e.location_lists[0]?.key === 'overseas'
                ? 'overseas'
                : e.key,
            type: 'region',
            label: (
              <span className='reset-label'>
                {AllText} {item.label}
              </span>
            )
          })
          e.location_lists.map((k) => {
            item.children.push({
              id: k.id,
              value: k.id,
              key: k.key,
              type: 'location',
              label: <span className='reset-label'>{k.value}</span>
            })
          })
        } else {
          item.label = showFirstLevel ? (
            <span className='reset-label'>{e.value || e.display_name}</span>
          ) : (
            e.value || e.display_name
          )
        }

        list.push(item)
      })
      if (!showFirstLevel) {
        list[0].children = [
          {
            id: findLists.id,
            key: findLists.key,
            value: findLists.id,
            label: <span className='reset-label'>{AllText + ' ' + findLists.value}</span>,
            type: 'country'
          }
        ]
      }
      setCountryLists(list)
    }
  }, [findLists, AllText, showFirstLevel])

  useEffect(() => {
    if (preference?.id && countryLists?.length > 0) {
      const { pref_country_id, pref_region_id, location_id } = preference || {}
      let arr = []
      if (pref_country_id) {
        arr = showFirstLevel ? [pref_country_id] : [pref_country_id, pref_country_id]
      } else if (pref_region_id) {
        arr = showFirstLevel ? [pref_region_id] : [pref_region_id, pref_region_id]
      } else if (location_id) {
        for (const country of countryLists) {
          const location = country.children.find((k) => k.id === location_id)
          if (location) {
            arr = [country.id, location_id]
            break
          }
        }
      }

      setValue(arr)

      // TODO: need refactor @taylor
      if (countryLists.length) {
        countryLists.forEach((country) => {
          const _id = pref_country_id || pref_region_id
          if ((pref_country_id || pref_region_id) && country.id == _id) {
            onChange(country)
          } else {
            const children = country.children
            if (location_id && children) {
              const country = children.find((e) => e.id === location_id)
              if (country) {
                onChange(country)
              }
            }
          }
        })
      }
    }
  }, [preference, countryLists, showFirstLevel])
  const handleChange = (value, selectedOptions) => {
    if (selectedOptions?.length > 0) {
      const result = selectedOptions[selectedOptions?.length - 1]
      onChange(result)
      setValue(value || [])
    } else {
      onChange('')
      setValue(value || [])
    }
  }
  const dropdownRender = (menus: React.ReactNode) => (
    <>
      {isMobile ? (
        <>
          <div className='pop-header'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
            >
              <path
                d='M4 4L20 20'
                stroke='#121212'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M4 20L20 4'
                stroke='#121212'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <span>Location</span>
          </div>
          {menus}
        </>
      ) : (
        <div>{menus}</div>
      )}
    </>
  )
  const displayRender = (labels, selectedOptions) => labels[labels.length - 1]

  return (
    <StyleProvider hashPriority='high'>
      <div
        className={`custom-input-container ${isFocused ? 'focused' : ''} ${
          variant === 'borderLess' ? 'custom-input-borderLess' : ''
        }  ${value?.length > 0 && 'custom-input-hasValue'}`}
        style={{ width: '100%' }}
      >
        {variant !== 'standard' && label && (
          <label className='floating-label'>
            {required && <i>*</i>}
            {label}
          </label>
        )}

        <Cascader
          {...rest}
          open={open}
          expandTrigger={isMobile ? 'click' : 'hover'}
          style={error ? { border: '1px solid red', borderRadius: '4px' } : {}}
          options={countryLists}
          size='middle'
          value={value}
          displayRender={displayRender}
          suffixIcon={SuffixIcon ? <SuffixIcon /> : <ArrowDropDownRoundedIcon />}
          expandIcon={
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
            >
              <path
                d='M4.26978 9.64833L7.72998 6.01261L4.26978 2.35181'
                stroke='#B8B8B8'
                strokeWidth='1.25'
                strokeLinecap='round'
              />
            </svg>
          }
          onDropdownVisibleChange={setOpen}
          dropdownRender={dropdownRender}
          popupClassName={`custom-input-popup ${isMobile && 'popup-mobile'} ${
            showFirstLevel && isMobile && 'popup-mobile-full'
          }`}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <p className='error'>{error && error?.message}</p>
      </div>
    </StyleProvider>
  )
}

export default CascaderComponent

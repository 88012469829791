'use client'
import React, { useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useRouter, usePathname } from 'next/navigation'
import styles from '../Header.module.scss'
import Link from 'components/Link'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { DefaultAvatar } from 'images'
import CountryLanguage from '../PublicHeader/countryLanguage'
import dynamic from 'next/dynamic'
import { Popover } from 'antd'
import { getCookie, setCookie, removeCookie } from 'helpers/cookies'

const base64DefaultAvatar =
  'data:image/webp;base64,UklGRpoBAABXRUJQVlA4WAoAAAAQAAAALwAALwAAQUxQSD4AAAABT6CgbRuGP+b+jUREZB5JAkWxrVRAAjJgg08DCxjBCEQwgra+W11F9H8CSrB1q7C+nkti558wTKnEZm/suFZQOCA2AQAAUAkAnQEqMAAwAD6JNpZHpSKiITHWbfigEQloAKwywkPno3mRWTtHRANuzdoAI9DOkbLEvbwD4DlpYYVck2PSPNz8VA13iNzGN/iy8NVbaw4nKYAA/u/YP+i7fl/zq7fG+Y4L8UPHMP/xDgpN57//xUXRCaada7X2Jqu3tA3lRQc9qAjeoUtiilX40bN5u12QNEbD7VfUdSbn1Uzt33h99JQQXpaBju+9OQ24VimFveCzbveLLRHyLjmkS2ZuufJ+6l8zTjSm9oDpA+TmVdZBA85eVU+hqrDB4SUgMi06TTFPUwOf15QAR8IZEcFf/8MSIjwOqr22R+4wodYDSPmPq0VHmBjgeFV8yw/1hzLSL8NijwFM8CK5lR++xwgqR+cput0l1jUB/AK+vwNnLkvULLJhrc4AAA=='

interface IProps {
  langKey: string
  lang: any
  userInfo: any
  initUnread?: number
  countryKey: string
  isMobile?: boolean
}

const Dropdown = dynamic(() => import('antd/es/dropdown'), { ssr: false })
const DropDownMenu = dynamic(() => import('../Common/DropDownMenu'), { ssr: false })

const NavRight = (props: IProps) => {
  const isLogin = getCookie('sessionid') ? true : false
  const isShowRemindCompleteResumeTag = getCookie('remind_user_complete_resume') ? true : false

  const { langKey, lang, userInfo, initUnread = 0, countryKey, isMobile } = props
  const router = useRouter()
  const pathname = usePathname()
  const { manageResume, Chat } = lang || {}
  const unread =
    typeof window !== 'undefined' ? useSharedData('CHAT_TOTAL_UNREAD') ?? initUnread : initUnread
  const totalUnread = unread
  const [showUnCompletedDot, setShowUnCompletedDot] = useState(false)
  const [remindCompleteResume, setRemindCompleteResume] = useState(false)

  const [open, setOpen] = useState(false)
  const [unreadNotification, setUnreadNotification] = useState(0)

  const isVip = useMemo(() => userInfo?.vip?.is_vip, [userInfo])
  const { avatar } = userInfo
  useEffect(() => {
    if (userInfo?.job_preferences) {
      const hasJobPreferences = userInfo.job_preferences?.length > 0
      setShowUnCompletedDot(!userInfo?.is_profile_completed || !hasJobPreferences)
    }
  }, [userInfo])

  useEffect(() => {
    if (showUnCompletedDot && !isShowRemindCompleteResumeTag && isLogin) {
      setRemindCompleteResume(true)
      setCookie('remind_user_complete_resume', true)
    }
  }, [showUnCompletedDot, isShowRemindCompleteResumeTag, isLogin])

  useEffect(() => {
    if (!isLogin) {
      removeCookie('remind_user_complete_resume')
    }
  }, [isLogin])

  useEffect(() => {
    const timer = setTimeout(() => {
      setRemindCompleteResume(false)
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [remindCompleteResume])

  const Avatar = (
    <img
      src={avatar ?? base64DefaultAvatar}
      width={isMobile ? 26 : 40}
      height={isMobile ? 26 : 40}
      style={{ borderRadius: '50%', border: isVip ? '1px solid #EBEEF0' : '' }}
      alt='avatar'
      onError={(e) => {
        console.log({ userInfoOnError: e })
        ;(e.target as HTMLInputElement).src = DefaultAvatar
      }}
    />
  )

  const completeProfileContent = (
    <div className={styles.completeProfileContent}>
      <div
        className={classNames(styles.completeProfileTitle, {
          [styles.completeProfileTitleBold]: langKey === 'ja' || langKey === 'zh-cn'
        })}
      >
        プロフィールを作成しましょう！
      </div>

      <p> 履歴書などのプロフィールを改善することで、面接の招待を受ける確率が向上できます。</p>

      <RemindUserCompleteProfile />
    </div>
  )

  return (
    <ul className={styles.headerLinksList}>
      <React.Fragment>
        <li
          className={classNames([styles.headerLink, styles.headerLinkRightItem])}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <div className={styles.countryItem}>
            {pathname !== '/chat/[chat_id]' ? (
              <Link title='Jobs' to={'/' + langKey + '/chat/list'}>
                <span className={classNames([styles.headerLinkText, styles.headerRightLinkText])}>
                  {Chat}
                  {totalUnread ? (
                    <span
                      className={classNames({
                        [styles.unread]: true,
                        [styles.unread_99]: Number(totalUnread) > 99
                      })}
                    >
                      {Number(totalUnread) > 99 ? '99+' : totalUnread}
                    </span>
                  ) : null}
                </span>
              </Link>
            ) : (
              <span className={classNames([styles.headerLinkText, styles.headerRightLinkText])}>
                {Chat}
                {totalUnread ? (
                  <span
                    className={classNames({
                      [styles.unread]: true,
                      [styles.unread_99]: Number(totalUnread) > 99
                    })}
                  >
                    {Number(totalUnread) > 99 ? '99+' : totalUnread}
                  </span>
                ) : null}
              </span>
            )}
          </div>
        </li>
        <li
          className={classNames([
            styles.headerLink,
            styles.headerLinkLogin,
            styles.headerLinkRightItem
          ])}
        >
          <div className={styles.countryItem}>
            <Popover
              content={completeProfileContent}
              title={null}
              open={remindCompleteResume}
              overlayClassName={styles.overlayClass}
            >
              <Link
                style={{
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  position: 'relative',
                  display: 'flex',
                  lineHeight: '16.5px'
                }}
                className={classNames(showUnCompletedDot ? styles.unCompleted : '')}
                title={manageResume}
                to={!pathname.includes('/manage-profile') ? '/' + langKey + '/manage-profile' : ''}
              >
                <span className={classNames([styles.headerLinkText, styles.headerRightLinkText])}>
                  {manageResume}
                </span>
              </Link>
            </Popover>
          </div>
        </li>
        <li className={classNames([styles.headerLink, styles.headerLinkRightItem])}>
          <div className={styles.countryItem + ' ' + styles.countryItemCountry}>
            <CountryLanguage countryKey={countryKey} />
          </div>
        </li>
        <li className={classNames([styles.headerLink, styles.headerLinkRightItem])}>
          <Dropdown
            placement='bottomRight'
            trigger={['hover']}
            open={open}
            onOpenChange={(open) => {
              setOpen(open)
            }}
            forceRender
            overlayClassName={styles.authUserMenu}
            dropdownRender={() => {
              return (
                <DropDownMenu
                  ref={undefined}
                  langKey={langKey}
                  lang={lang}
                  pathname={pathname}
                  setDorpDownOpen={setOpen}
                  onUnreadChange={setUnreadNotification}
                />
              )
            }}
          >
            <div
              className={classNames(
                styles.profileProtectedWrapper,
                unreadNotification ? styles.profileHasUnread : ''
              )}
            >
              {isVip ? (
                <div className={styles.vipAvatar}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='10'
                    viewBox='0 0 24 10'
                    fill='none'
                    style={{
                      position: 'absolute',
                      bottom: isMobile ? '-1px' : '-1px',
                      right: isMobile ? '-8px' : '-5px'
                    }}
                  >
                    <path
                      d='M7.25286 3.19689C7.96334 1.82348 9.32153 0.893372 10.8678 0.893372H18.8283C21.3342 0.893372 23.3657 2.90809 23.3657 5.39337C23.3657 7.87865 21.3342 9.89337 18.8283 9.89337H0.365723C0.365723 9.89337 2.403 9.00828 3.21554 8.40617C5.01283 7.07434 6.37525 4.89336 7.25286 3.19689Z'
                      fill='#FFE1D0'
                    />
                    <g filter='url(#filter0_i_6874_166131)'>
                      <path
                        d='M9.28418 2.54843H10.4196L10.9794 5.92645L11.0661 6.63802H11.1292L11.4682 5.92645L13.2816 2.54843H14.4564L11.413 8.14718H10.2934L9.28418 2.54843Z'
                        fill='#FF9000'
                      />
                      <path
                        d='M14.9822 2.54843H16.0466L15.0453 8.14718H13.9809L14.9822 2.54843Z'
                        fill='#FF9000'
                      />
                      <path
                        d='M16.049 8.14718L17.0503 2.54843H18.9426C19.1949 2.54843 19.4262 2.58753 19.6364 2.66572C19.8467 2.74392 20.028 2.85339 20.1805 2.99414C20.3382 3.13489 20.4591 3.30431 20.5432 3.50241C20.6325 3.7005 20.6772 3.91945 20.6772 4.15924C20.6772 4.46681 20.622 4.74049 20.5116 4.98029C20.4065 5.22009 20.2567 5.42339 20.0622 5.59021C19.8677 5.75181 19.6338 5.87692 19.3605 5.96554C19.0924 6.04895 18.7981 6.09065 18.4774 6.09065H17.4761L17.1134 8.14718H16.049ZM18.6193 5.08976C18.9032 5.08976 19.1397 5.02199 19.3289 4.88646C19.5182 4.7457 19.6128 4.52937 19.6128 4.23744C19.6128 4.03413 19.5497 3.86992 19.4236 3.74481C19.3027 3.61449 19.1239 3.54932 18.8874 3.54932H17.9334L17.6574 5.08976H18.6193Z'
                        fill='#FF9000'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_i_6874_166131'
                        x='9.28418'
                        y='2.54843'
                        width='11.3931'
                        height='6.09875'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood flood-opacity='0' result='BackgroundImageFix' />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='BackgroundImageFix'
                          result='shape'
                        />
                        <feColorMatrix
                          in='SourceAlpha'
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          result='hardAlpha'
                        />
                        <feOffset dy='0.5' />
                        <feGaussianBlur stdDeviation='0.25' />
                        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                        <feColorMatrix
                          type='matrix'
                          values='0 0 0 0 1 0 0 0 0 0.250837 0 0 0 0 0.182731 0 0 0 0.25 0'
                        />
                        <feBlend
                          mode='normal'
                          in2='shape'
                          result='effect1_innerShadow_6874_166131'
                        />
                      </filter>
                    </defs>
                  </svg>
                  {Avatar}
                </div>
              ) : (
                Avatar
              )}
            </div>
          </Dropdown>
        </li>
      </React.Fragment>
    </ul>
  )
}

const RemindUserCompleteProfile = () => (
  <svg
    width='138'
    height='112'
    viewBox='0 0 138 112'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M119.027 0V16.9313H100.191V0H99.191V16.9313H80.3553V0H79.3553V16.9313H60.5197V0H59.5197V16.9313H40.684V0H39.684V16.9313H20.8483L20.8483 0H19.8483L19.8483 16.9313H0V17.9313H19.8483V36.6961H0V37.6961H19.8483L19.8483 56.4609H0V57.4609H19.8483L19.8483 76.2253H0V77.2253H19.8483V95.9897H0V96.9897H19.8483L19.8483 112H20.8483L20.8483 96.9897H39.684V112H40.684V96.9897H59.5197V112H60.5197V96.9897H79.3553V112H80.3553V96.9897H99.191V112H100.191V96.9897H119.027V112H120.027V96.9897H138V95.9897H120.027V77.2253H138V76.2253H120.027V57.4609H138V56.4609H120.027L120.027 37.6961H138V36.6961H120.027V17.9313H138V16.9313H120.027V0H119.027ZM20.8483 95.9897V77.2253H39.684L39.684 95.9897H20.8483ZM20.8483 76.2253L20.8483 57.4609H39.684V76.2253H20.8483ZM20.8483 56.4609L20.8483 37.6961H39.684V56.4609H20.8483ZM20.8483 36.6961V17.9313H39.684L39.684 36.6961H20.8483ZM59.5197 17.9313H40.684L40.684 36.6961H59.5197L59.5197 17.9313ZM59.5197 37.6961H40.684V56.4609H59.5197V37.6961ZM59.5197 57.4609H40.684V76.2253H59.5197V57.4609ZM59.5197 77.2253H40.684L40.684 95.9897H59.5197L59.5197 77.2253ZM60.5197 95.9897L60.5197 77.2253H79.3553V95.9897H60.5197ZM60.5197 76.2253V57.4609H79.3553V76.2253H60.5197ZM60.5197 56.4609V37.6961H79.3553L79.3553 56.4609H60.5197ZM60.5197 36.6961L60.5197 17.9313H79.3553V36.6961H60.5197ZM99.191 17.9313H80.3553V36.6961H99.191V17.9313ZM99.191 37.6961H80.3553L80.3553 56.4609H99.191L99.191 37.6961ZM99.191 57.4609H80.3553V76.2253H99.191V57.4609ZM99.191 77.2253H80.3553V95.9897H99.191V77.2253ZM100.191 95.9897V77.2253H119.027V95.9897H100.191ZM100.191 76.2253V57.4609H119.027V76.2253H100.191ZM100.191 56.4609L100.191 37.6961H119.027L119.027 56.4609H100.191ZM100.191 36.6961V17.9313H119.027V36.6961H100.191Z'
      fill='url(#paint0_radial_437_8305)'
      fill-opacity='0.6'
    />
    <path
      d='M39 99.4598C45.3738 103.252 62.0719 108.885 77.8734 101.085C97.6253 91.3343 97.4152 74 122 74'
      stroke='url(#paint1_linear_437_8305)'
      stroke-width='5'
    />
    <g filter='url(#filter0_d_437_8305)'>
      <circle cx='120' cy='73' r='3' fill='#66C61C' />
      <circle cx='120' cy='73' r='4' stroke='white' stroke-width='2' />
    </g>
    <defs>
      <filter
        id='filter0_d_437_8305'
        x='111'
        y='64'
        width='18'
        height='18'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.441512 0 0 0 0 0.547519 0 0 0 0 0.705268 0 0 0 1 0'
        />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_437_8305' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_437_8305'
          result='shape'
        />
      </filter>
      <radialGradient
        id='paint0_radial_437_8305'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(94.0165 93.0462) rotate(-136.939) scale(54.8884 3144.41)'
      >
        <stop offset='0.3' stop-color='#CDE1FF' />
        <stop offset='1' stop-color='white' stop-opacity='0.08' />
      </radialGradient>
      <linearGradient
        id='paint1_linear_437_8305'
        x1='114.49'
        y1='74'
        x2='40.2586'
        y2='112.718'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#1C70EE' />
        <stop offset='1' stop-color='white' stop-opacity='0' />
      </linearGradient>
    </defs>
  </svg>
)

export default NavRight
